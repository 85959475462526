/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prefer-stateless-function */
import { h, render, Component } from 'preact';
import style from './style';

class Image extends Component {
	render = (props) => {
	  return (
	    <div className={`${style.imageComp}`}>
	      <img src={`${props.image.src}`} />
	    </div>
	  );
	};
}

export default Image;
