import {
  fetchGrids,
  fetchPosts,
  fetchFeaturedPosts,
  fetchTags,
  setLanguage,
  fetchMenu,
  fetchLanguages
} from '_mutations';

// eslint-disable-next-line import/prefer-default-export
export function prefetch () {
  return Promise.all([
    fetchGrids(),
    fetchLanguages(),
    setLanguage(),
    fetchMenu(),
    fetchPosts(),
    fetchFeaturedPosts(),
    fetchTags()
  ]);
}
