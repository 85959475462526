import { h } from 'preact';
// eslint-disable-next-line import/extensions
import style from './style';

export default (props) => (
  <button
    className={style.button}
    style={props.theme}
    onClick={props.onClick}
    type="button"
  >
    {props.children}
  </button>
);
