/* eslint-disable react/jsx-props-no-spreading */
import { h } from 'preact';
// eslint-disable-next-line import/extensions
import { getTranslation } from '_helpers';
import style from './style.scss';

export default (props) => (
  <div className={style.formInput}>
    <label htmlFor={props.id || ''}>{props.title}</label>
    {props.type === 'date' ? (
      <div>
        <p className={style.dateValue}>{props.formattedDateValue}</p>
        <input
          {...props}
          style={
            props.errors && props.errors.length ? { borderColor: 'red' } : null
          }
        />
      </div>
    ) : (
      <input
        {...props}
        style={
          props.errors && props.errors.length ? { borderColor: 'red' } : null
        }
      />
    )}
    {props.errors && !props.customErrors && !props.hideErrorMessages
      ? props.errors.map((err) => {
        return (
          <span className={style.errorMessage}>
            {getTranslation(err)}
*
          </span>
        );
			  })
      : null}
  </div>
);
