/* eslint-disable react/no-unknown-property */
import { h } from 'preact';
// eslint-disable-next-line import/extensions
import { getTranslation, replaceUrlPlaceholders } from '_helpers';
import style from './style.scss';

export default (props) => {
  const defaultData =
		props && props.data && props.data.find((d) => d.Value === props.value);
  return (
    <div className={style.formDropDown}>
      <label htmlFor={props.id}>{props.title}</label>
      <select
        title={props.title}
        className={style.formDropDown}
        autoCorrect={props.autoCorrect}
        autoCapitalize={props.autoCapitalize}
        errors={props.errors}
        value={defaultData && defaultData.Value}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onInput={props.onInput}
        onChange={props.onChange}
        hideerrormessage={props.hideerrormessage}
        id={props.id}
        name={props.name}
        style={{
          background: `url(${replaceUrlPlaceholders(
            '{ASSET_DOMAIN_CDN}icons/v1/down%20arrow.png'
          )}) 100% no-repeat`,
          backgroundSize: '20px 20px',
          borderColor: `${props.errors && props.errors.length ? 'red' : ''}`
        }}
      >
        <option selected>{getTranslation('LABEL_SELECT_DEFAULT')}</option>
        {props.data &&
					props.data.map((i) => {
					  return <option value={i.Value}>{i.Text}</option>;
					})}
      </select>
      {props.errors && props.name !== 'countryCode'
        ? props.errors.map((err) => {
          return (
            <span className={style.errorMessage}>
              {getTranslation(err)}
*
            </span>
          );
				  })
        : null}
    </div>
  );
};
