/* eslint-disable import/prefer-default-export */
import { xhr, urlMenu, replaceUrlPlaceholders } from '_helpers';
import { store, updateStore } from '../unistore';

export function fetchMenu () {
  const { menu } = store.getState();

  // initial state
  updateStore({
    menu: {
      ...menu,
      fetching: true,
      result: false
    }
  });

  return xhr(replaceUrlPlaceholders(urlMenu), null, true)
    .then((res) => {
      updateStore({
        menu: {
          data: res,
          fetching: false,
          result: true
        }
      });
    })
    .catch(() => {
      updateStore({
        menu: {
          ...menu,
          fetching: false,
          result: false
        }
      });
    });
}
