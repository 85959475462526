import {
  xhr,
  urlPosts,
  urlFeaturedPosts,
  urlTags,
  replaceUrlPlaceholders
} from '_helpers';
import { store, updateStore } from '../unistore';

export function fetchPosts () {
  const { posts } = store.getState();

  // initial state
  updateStore({
    posts: {
      ...posts,
      fetching: true,
      result: false
    }
  });

  return xhr(replaceUrlPlaceholders(urlPosts), null, true)
    .then((res) => {
      updateStore({
        posts: {
          data: res,
          fetching: false,
          result: true
        }
      });
    })
    .catch(() => {
      updateStore({
        posts: {
          ...posts,
          fetching: false,
          result: false
        }
      });
    });
}

export function fetchFeaturedPosts () {
  const { featuredPosts } = store.getState();

  // initial state
  updateStore({
    featuredPosts: {
      ...featuredPosts,
      fetching: true,
      result: false
    }
  });

  return xhr(replaceUrlPlaceholders(urlFeaturedPosts), null, true)
    .then((res) => {
      updateStore({
        featuredPosts: {
          data: res,
          fetching: false,
          result: true
        }
      });
    })
    .catch(() => {
      updateStore({
        featuredPosts: {
          ...featuredPosts,
          fetching: false,
          result: false
        }
      });
    });
}

export function fetchTags () {
  const { tags } = store.getState();

  // initial state
  updateStore({
    tags: {
      ...tags,
      fetching: true,
      result: false
    }
  });

  return xhr(replaceUrlPlaceholders(urlTags), null, true)
    .then((res) => {
      updateStore({
        tags: {
          data: res,
          fetching: false,
          result: true
        }
      });
    })
    .catch(() => {
      updateStore({
        tags: {
          ...tags,
          fetching: false,
          result: false
        }
      });
    });
}
