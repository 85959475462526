import { h } from 'preact';
// eslint-disable-next-line import/extensions
import { getTranslation } from '_helpers';
import style from './style.scss';

export default (props) => {
  return (
    <div className={style.radio}>
      <label htmlFor={props.id || ''}>{props.title}</label>
      <div>
        {props.data
          ? props.data.map((item) => {
            return (
              <span>
                <input
                  name={props.name}
                  id={item.value}
                  type={props.type}
                  value={item.value}
                  className={style.radio}
                  onChange={props.onChange}
                  checked={props.checked === item.value}
                />
                <label
                  htmlFor={item.value}
                  className={
                    item.label === 'USDT' ? style.usdtLabel : style.radioLabel
                  }
                >
                  {getTranslation(item.label)}
                </label>
              </span>
            );
					  })
          : null}
      </div>
    </div>
  );
};
