/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable space-before-blocks */
import {
  xhr,
  urlLanguage,
  getCookie,
  setCookie,
  replaceUrlPlaceholders
} from '_helpers';
import { store, updateStore } from '../unistore';

export function checkIfLangIsSupported (lang) {
  const globalStore = store.getState();
  const languages = globalStore.languages;
  const supportedLangs = [];
  if (languages.data) {
    // eslint-disable-next-line array-callback-return
    languages.data.map((language) => {
      supportedLangs.push(language.code);
    });
    return supportedLangs.indexOf(lang) > 0;
  }
  return false;
}

export function setLanguage (language) {
  const langCookie = getCookie('lang');
  const globalStore = store.getState();

  // TODO - Could be better...

  if (language && !langCookie) {
    // eslint-disable-next-line
		console.log(
      `Mutations >> Language >> setLanguage >> Setting language to ${language}`
    );
    store.setState({ selectedLanguage: language });
    setCookie('lang', language);
  } else if (!language && langCookie) {
    // eslint-disable-next-line
		console.log(
      `Mutations >> Language >> setLanguage >> Setting language from cookie ${langCookie}`
    );
    store.setState({ selectedLanguage: langCookie });
  } else if (language && langCookie) {
    // eslint-disable-next-line no-console
    console.log(
      `Mutations >> Language >> setLanguage >> Setting language ${language}`
    );
    store.setState({ selectedLanguage: language });
    setCookie('lang', language);
  } else if (!globalStore.selectedLanguage && langCookie) {
    store.setState({ selectedLanguage: langCookie });
    setCookie('lang', langCookie);
  } else if (globalStore.selectedLanguage && !langCookie) {
    // eslint-disable-next-line no-console
    console.log(
      'Mutations >> Language >> setLanguage >> Language is already set'
    );
    store.setState({ selectedLanguage: globalStore.selectedLanguage });
  } else if (!globalStore.selectedLanguage && !langCookie && !language) {
    // eslint-disable-next-line
		console.log(
      'Mutations >> Language >> setLanguage >> No language set, using default.'
    );
    store.setState({
      selectedLanguage: navigator.language
        ? checkIfLangIsSupported(navigator.language.toLowerCase())
          ? navigator.language.toLowerCase()
          : 'en-us'
        : 'en-us'
    });
  } else {
    // eslint-disable-next-line
		console.log(
      'Mutations >> Language >> setLanguage >> Language is already set'
    );
  }
}

export function fetchLanguages () {
  const { languages } = store.getState();

  // initial state
  updateStore({
    languages: {
      ...languages,
      fetching: true,
      result: false
    }
  });

  return xhr(replaceUrlPlaceholders(urlLanguage), null, true)
    .then((res) => {
      updateStore({
        languages: {
          data: res,
          fetching: false,
          result: true
        }
      });
    })
    .catch(() => {
      updateStore({
        languages: {
          ...languages,
          fetching: false,
          result: false
        }
      });
    });
}
