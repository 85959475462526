/** @format */

import { getTranslation } from '_helpers';
import validator from '../lib/smart-input-validator';

export function validateAmount (value) {
  return validator(
    {
      value
    },
    {
      value: 'required; allowedChars:decimals'
    },
    {
      value: {
        _$all: 'RequiredField'
      }
    }
  );
}

export function validateEmail (value) {
  return validator(
    {
      value
    },
    {
      value: 'required; email'
    },
    {
      value: {
        required: 'LABEL_REGISTER_DESC_EMAIL',
        email: 'Reg_InvalidEmail'
      }
    }
  );
}

export function validatePhoneNumber (value) {
  return validator(
    {
      value
    },
    {
      value: 'required; allowedChars:numbers; maxLen:12'
    },
    {
      value: {
        _$all: 'LABEL_REGISTER_DESC_CONTACT'
      }
    }
  );
}

export function validateName (value) {
  if (!value || !value.trim().length) {
    return ['LABEL_REGISTER_DESC_FULLNAME'];
  }

  if (!/^[^~@!#$%^&*()+={}|\[\]\\<>?,/:";\d]*$/.test(value)) {
    return ['Reg_WrongName'];
  }

  return [];
}

export function validateUsername (value) {
  return validator(
    {
      value
    },
    {
      value: 'required; maxLen:16; allowedChars:alphabets,numbers'
    },
    {
      value: {
        allowedChars: 'LABEL_REGISTER_ERR_USERNAME',
        _$all: 'LABEL_REGISTER_DESC_USERNAME'
      }
    }
  );
}

export function validatePasswordMC (username, password, existingErrors) {
  const errors = existingErrors || [];

  if (!username || !password) {
    return errors;
  }

  try {
    if (
      errors.indexOf('Reg_InvalidPasswordMC') < 0 &&
			username.toLowerCase() === password.toLowerCase()
    ) {
      return errors.concat('Reg_InvalidPasswordMC');
    }
    if (username.toLowerCase() === password.toLowerCase()) {
      throw new Error();
    }

    return errors;
  } catch (e) {
    return errors.filter((err) => err !== 'Reg_InvalidPasswordMC');
  }
}

export function validatePasswordsMatch (passwordA, passwordB, existingErrors) {
  const errors = existingErrors || [];

  if (!passwordA || !passwordB) {
    return errors;
  }

  try {
    if (
      errors.indexOf('ChangePassword_Mismatch') < 0 &&
			passwordA !== passwordB
    ) {
      return errors.concat('ChangePassword_Mismatch');
    }
    if (passwordA === passwordB) {
      throw new Error();
    }

    return errors;
  } catch (e) {
    return errors.filter((err) => err !== 'ChangePassword_Mismatch');
  }
}

export function validateOldPassword (value) {
  return validator(
    {
      value
    },
    {
      value: 'required'
    },
    {
      value: {
        required: 'LABEL_REGISTER_DESC_PASSWORD'
      }
    }
  );
}

export function validatePassword (value, overrideMessages) {
  const errors = validator(
    {
      value
    },
    {
      value: 'required; betweenLen:6,20'
    },
    {
      value: {
        required:
					overrideMessages && 'required' in overrideMessages
					  ? overrideMessages.required
					  : 'RequiredField',
        betweenLen:
					overrideMessages && 'betweenLen' in overrideMessages
					  ? overrideMessages.betweenLen
					  : getTranslation('ERR_LENGTH')
					    .replace(/\$1/gim, 6)
					    .replace(/\$2/gim, 20)
      }
    }
  );

  if (value.length) {
    // all numeric => fail
    if (!/[^\d]/gim.test(value)) {
      errors.push('ERR_ALPHANUMERIC');
    }
    if (/ /gim.test(value)) {
      errors.push('ERR_SPACE');
    }
  }

  return errors;
}

export function validateRequiredField (value, overrideMessages) {
  return validator(
    {
      value
    },
    {
      value: 'required'
    },
    {
      value: {
        required:
					overrideMessages && 'required' in overrideMessages
					  ? overrideMessages.required
					  : 'RequiredField'
      }
    }
  );
}

export function validateAccountNumber (value) {
  return validator(
    {
      value
    },
    {
      value: 'required; allowedChars:numbers; minLen:6'
    },
    {
      value: {
        allowedChars: 'Pay_InvalidAccountNumber',
        minLen: 'Pay_InvalidAccountNumber',
        required: 'RequiredField'
      }
    }
  );
}

export function validateCaptcha (value) {
  return validator(
    {
      value
    },
    {
      value: 'required; maxLen:5; allowedChars:alphabets,numbers'
    },
    {
      value: {
        allowedChars: 'Login_IncorrectVCode',
        _$all: 'LABEL_REGISTER_DESC_CODE'
      }
    }
  );
}

export function validateDateOfBirth (date) {
  const newDate = new Date();
  const presentYear = newDate.getFullYear();
  const dobValue = `${date.year}-${date.month}-${date.date}`;
  const invalidDate = [
    'Reg_InvalidDOB'
  ]; /* Return this for now until we can extend this function */

  const validatePattern = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/;
  const dateValues = dobValue.match(validatePattern);
  if (dateValues === null) {
    return invalidDate;
  }

  const birthYear = parseInt(dateValues[1]);
  const birthMonth = parseInt(dateValues[3]);
  const birthDate = parseInt(dateValues[5]);

  if (
    new Date(`${birthMonth}/${birthDate}/${birthYear}`) >
		new Date().setYear(new Date().getFullYear() - 18)
  ) {
    // less than 18 years of age
    return invalidDate;
  }
  if (presentYear - birthYear >= 100) {
    // greater or equal to 100 years of age
    return invalidDate;
  }
  if (birthMonth < 1 || birthMonth > 12) {
    return invalidDate;
  }
  if (birthDate < 1 || birthDate > 31) {
    return invalidDate;
  }
  if (
    (birthMonth === 4 ||
			birthMonth === 6 ||
			birthMonth === 9 ||
			birthMonth === 11) &&
		birthDate === 31
  ) {
    return invalidDate;
  }
  if (birthMonth === 2) {
    const isLeapYear =
			birthYear % 4 === 0 && (birthYear % 100 !== 0 || birthYear % 400 === 0);
    if (birthDate > 29 && isLeapYear) {
      return invalidDate;
    }
    if (birthDate > 28 && !isLeapYear) {
      return invalidDate;
    }
  }

  return [];
}
