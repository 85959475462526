/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-fragments */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { h, Component } from 'preact';
import {
  replaceUrlPlaceholders,
  getTranslation,
  validateUsername,
  validatePassword,
  validatePasswordMC,
  validatePasswordsMatch,
  validateEmail,
  validateRequiredField,
  validatePhoneNumber,
  validateName,
  validateDateOfBirth,
  messageModal,
  displayPageLoader,
  xhr,
  getQueryStringValue,
  overrideCurrencyCode,
  getCookie,
  platform,
  urlRegister
} from '_helpers';
import {
  FormGroup,
  FormInput,
  FormDropdown,
  Text,
  Button,
  LoaderRing
} from '_components/core';
import { fetchCountryPhoneList, fetchCurrency } from '_mutations';
import { connect } from 'unistore/preact';
import style from './style.scss';

class FormSample extends Component {
  constructor (props) {
    super(props);
    this.state = {
      fullname: {
        value: '',
        errors: []
      },
      username: {
        value: '',
        errors: []
      },
      password: {
        value: '',
        errors: []
      },
      confirmPassword: {
        value: '',
        errors: []
      },
      email: {
        value: '',
        errors: []
      },
      countryCode: {
        value: '',
        errors: []
      },
      phone: {
        value: '',
        errors: []
      },
      currencyCode: {
        value: '',
        errors: []
      },
      dateOfBirth: {
        month: '',
        date: '',
        year: ''
      },
      affiliateId: '',
      hasAffiliateId: '',
      depositPayments: {
        result: null,
        data: null
      },
      countryCodeOptions: {
        data: null,
        result: null
      },
      currencyOptions: {
        data: null,
        result: null
      },
      helpBlocks: {},
      focusedField: null,
      ts: Date.now(),
      target: '', // web|android|ios,
      fetching: false,
      params: {},
      usernameSuggestions: [],
      attempts: 1
    };
  }

  componentDidMount () {
    function prefetchData () {
      return Promise.all([fetchCountryPhoneList(), fetchCurrency()]);
    }
    prefetchData();

    if (platform.apiPlatform.indexOf('ios') > -1) {
      this.setInitialDOBPlaceholder();
    }
  }

	componentWillMount = () => {
	  const { countryPhoneList, currency } = this.props;

	  // get params
	  let params = {};
	  if (this.props.params && this.props.params.length) {
	    params = this.props.params.reduce((r, i) => {
	      return {
	        ...r,
	        [i]: getQueryStringValue(i)
	      };
	    }, {});
	    this.setState({
	      params
	    });
	  }

	  // set State
	  this.setState({
	    hasAffiliateId:
				getQueryStringValue('affiliateID') ||
				getCookie('affiliate') ||
				getCookie('AffiliateId') ||
				'',
	    sc: getQueryStringValue('sc') || null,
	    countryCode: {
	      value:
					(countryPhoneList &&
						countryPhoneList.data &&
						countryPhoneList.data.PhoneSelected) ||
					''
	    },
	    currencyCode: {
	      value:
					(currency && currency.data && currency.data.CurrencySelected) || ''
	    },
	    pending: false,
	    params
	  });
	  displayPageLoader(false);
	};

	setInitialDOBPlaceholder = () => {
	  console.log('SPA >> RegistrationsetInitialDOBPlaceholder');

	  const today = new Date();
	  // set initial or default date
	  try {
	    this.setState({
	      dateOfBirth: {
	        month:
						today.getMonth() + 1 < 10
						  ? `0${today.getMonth() + 1}`
						  : today.getMonth() + 1,
	        date: today.getDate() < 10 ? `0${today.getDate()}` : today.getDate(),
	        year: today.getFullYear() - 18
	      }
	    });
	  } catch (err) {
	    console.log('SPA Registration >> Failed to set default date', err);
	  }
	};

	submit = (e) => {
	  e.preventDefault();

	  const {
	    email,
	    username,
	    fullname,
	    password,
	    confirmPassword,
	    phone,
	    dateOfBirth,
	    countryCode,
	    currencyCode
	  } = this.state;

	  const { selectedLanguage } = this.props;

	  const validations = [
	    this.validateCountryCode(countryCode.value),
	    this.validateCurrencyCode(currencyCode.value),
	    this.validateEmail(email.value),
	    this.validateFullname(fullname.value),
	    this.validatePassword(password.value),
	    this.validatePhoneNumber(phone.value),
	    this.validateUsername(username.value),
	    this.validateDateOfBirth(dateOfBirth)
	  ];

	  if (selectedLanguage !== 'th') {
	    validations.push(this.validateConfirmPassword(confirmPassword.value));
	  }

	  const noErrors =
			!email.errors.length &&
			!username.errors.length &&
			!countryCode.errors.length &&
			!currencyCode.errors.length &&
			!fullname.errors.length;
	  !password.errors.length &&
			!confirmPassword.errors.length &&
			!phone.errors.length;

	  Promise.all(validations).then(() => {
	    if (noErrors) {
	      displayPageLoader(true);
	      if (this.state.sc) {
	        this.register('', true);
	      } else {
	        this.register('', false);
	      }
	    }
	  });
	};

	register = (VerificationCode, isSc, overrideData) => {
	  const {
	    username,
	    password,
	    confirmPassword,
	    email,
	    phone,
	    currencyCode,
	    fullname,
	    countryCode,
	    affiliateId,
	    hasAffiliateId,
	    target,
	    dateOfBirth,
	    sc,
	    params,
	    attempts
	  } = this.state;

	  const body = {
	    Username: username.value,
	    Password: password.value,
	    ConfirmPassword: confirmPassword.value,
	    Email: email.value,
	    Phone: phone.value,
	    CurrencyCode: currencyCode.value || overrideCurrencyCode(),
	    Firstname: fullname.value,
	    CountryCode: countryCode.value.replace(/\+ /gim, ''),
	    DateOfBirth: `${dateOfBirth.month}/${dateOfBirth.date}/${dateOfBirth.year} 0:0:0`,
	    AffiliateId: hasAffiliateId || affiliateId,
	    ReferralId: '',
	    Permission: '',
	    VerificationCode,
	    Target: target,
	    AffTrackId: getCookie('AffTid') || '',
	    Attempts: attempts,
	    ...(overrideData || {}),
	    ...(params || {}),
	    ...(isSc ? { Sc: sc } : {})
	  };
	  // register
	  xhr(urlRegister, {
	    method: 'post',
	    convertData: true,
	    data: body,
	    headers: {
	      'Content-Type': 'application/json'
	    }
	  })
	    .then((response) => {
	      // success or fail
	      console.log('==========>', response);
	      if (response && response.ResponseCode && response.ResponseCode === 1) {
	        messageModal({
	          title: getTranslation('Succesful'),
	          message: 'You are now registered!'
	        });
	        displayPageLoader(false);
	      } else {
	        messageModal({
	          title: getTranslation('Register'),
	          message: getTranslation(response.ResponseMessage)
	        });
	        displayPageLoader(false);
	      }
	    })
	    .catch((err) => {
	      // increment attempts
	      this.setState({
	        attempts:
						((err.response.ResponseData &&
							err.response.ResponseData.Attempts) ||
							this.state.attempts) + 1
	      });
	      console.log('SPA >> Error Register.', err);
	      displayPageLoader(false);
	    });
	};

	validateUsername = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    newState.username.value = value;
	    const errors = validateUsername(newState.username.value);

	    if (errors.length) {
	      newState.username.errors = ['RequiredField'];
	      newState.helpBlocks.username = errors;
	    } else {
	      newState.username.errors = [];
	      newState.helpBlocks.username = null;
	    }
	    this.setState(newState, resolve);
	  });
	};

	validatePassword = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    newState.password.value = value;
	    const errors = validatePassword(newState.password.value, {
	      required: 'LABEL_REGISTER_DESC_PASSWORD'
	    });

	    if (errors.length) {
	      newState.password.errors = ['RequiredField'];
	      newState.helpBlocks.password = errors;
	    } else {
	      newState.password.errors = [];
	      newState.helpBlocks.password = undefined;
	    }

	    this.setState(newState, resolve);
	  })
	    .then(this.validatePasswordsMatch)
	    .then(this.validatePasswordMC);
	};

	validatePasswordMC = () => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    const errors = validatePasswordMC(
	      this.state.username.value,
	      this.state.password.value,
	      this.state.password.errors
	    ).filter(
	      (err) =>
	        err !== 'RequiredField' &&
					err !== 'LABEL_REGISTER_DESC_PASSWORD_AGAIN'
	    );

	    if (errors.length) {
	      newState.password.errors = ['RequiredField'];
	      newState.helpBlocks.password = errors;
	    }

	    this.setState(newState, resolve);
	  });
	};

	validateConfirmPassword = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    newState.confirmPassword.value = value;
	    const errors = validateRequiredField(newState.confirmPassword.value, {
	      required: 'LABEL_REGISTER_DESC_PASSWORD_AGAIN'
	    });

	    if (errors.length) {
	      newState.confirmPassword.errors = ['RequiredField'];
	      newState.helpBlocks.confirmPassword = errors;
	    } else {
	      newState.confirmPassword.errors = [];
	      newState.helpBlocks.confirmPassword = undefined;
	    }

	    this.setState(newState, resolve);
	  }).then(this.validatePasswordsMatch);
	};

	validatePasswordsMatch = () => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    const errors = validatePasswordsMatch(
	      this.state.password.value,
	      this.state.confirmPassword.value,
	      this.state.confirmPassword.errors
	    ).filter(
	      (err) =>
	        err !== 'RequiredField' &&
					err !== 'LABEL_REGISTER_DESC_PASSWORD_AGAIN'
	    );

	    if (errors.length) {
	      newState.confirmPassword.errors = ['ChangePassword_Mismatch'];
	    }

	    this.setState(newState, resolve);
	  });
	};

	validateEmail = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    newState.email.value = value;
	    const errors = validateEmail(newState.email.value);

	    if (errors.length) {
	      newState.email.errors = ['RequiredField'];
	      newState.helpBlocks.email = errors;
	    } else {
	      newState.email.errors = [];
	      newState.helpBlocks.email = null;
	    }
	    this.setState(newState, resolve);
	  });
	};

	validateCountryCode = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    newState.countryCode.value = value;
	    const errors = validateRequiredField(newState.countryCode.value);

	    if (errors.length) {
	      newState.countryCode.errors = ['RequiredField'];
	      newState.helpBlocks.countryCode = errors;
	    } else {
	      newState.countryCode.errors = [];
	      newState.helpBlocks.countryCode = undefined;
	    }

	    this.setState(newState, resolve);

	    if (newState.countryCode.value === '66') {
	      this.validatePhoneNumber(this.state.phone.value);
	      this.setState({
	        phone: {
	          ...this.state.phone,
	          value: this.state.phone.value && this.state.phone.value.slice(0, 9)
	        }
	      });
	    } else {
	      this.setState({
	        phone: {
	          ...this.state.phone,
	          errors: []
	        }
	      });
	    }
	  });
	};

	validatePhoneNumber = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    const mobileLength = [];

	    newState.phone.value = value;
	    const errors = validatePhoneNumber(newState.phone.value);
	    const startingWithZero = newState.phone.value.startsWith('0');

	    if (
	      (errors.length || value.length < mobileLength.min) &&
				this.props.selectedLanguage === 'th' &&
				this.state.countryCode.value === '66'
	    ) {
	      newState.phone.errors = ['ERR_OMIT_ZERO'];
	      newState.helpBlocks.phone = errors;
	    } else if (value.length < 1) {
	      newState.phone.errors = ['RequiredField'];
	      newState.helpBlocks.phone = errors;
	    } else if (value.length > mobileLength.max) {
	      newState.phone.errors = [
	        getTranslation('ERR_MAX_CHARLENGTH').replace('{0}', mobileLength.max)
	      ];
	      newState.helpBlocks.phone = ['LABEL_REGISTER_DESC_CONTACT'];
	    } else if (value.length < mobileLength.min) {
	      newState.phone.errors = ['ERR_INVALIDNUMBER'];
	      newState.helpBlocks.phone = errors;
	    } else if (
	      (startingWithZero &&
					this.state.countryCode.value === '66' &&
					this.state.phone.value.trim().length <= mobileLength.min) ||
				(this.state.countryCode.value === '66' &&
					this.state.phone.value.trim().length < mobileLength.min)
	    ) {
	      newState.phone.errors = ['ERR_OMIT_ZERO'];
	      newState.helpBlocks.phone = errors;
	    } else {
	      newState.phone.errors = [];
	      newState.helpBlocks.phone = null;
	    }

	    this.setState(newState, resolve);
	  });
	};

	validateCurrencyCode = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    newState.currencyCode.value = value;
	    const errors = validateRequiredField(newState.currencyCode.value);

	    if (errors.length) {
	      newState.currencyCode.errors = ['RequiredField'];
	      newState.helpBlocks.currencyCode = errors;
	    } else {
	      newState.currencyCode.errors = [];
	      newState.helpBlocks.currencyCode = undefined;
	    }

	    this.setState(newState, resolve);
	  });
	};

	validateFullname = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    newState.fullname.value = value;
	    const errors = validateName(newState.fullname.value);

	    if (errors.length) {
	      newState.fullname.errors = ['RequiredField'];
	      newState.helpBlocks.fullname = errors;
	    } else {
	      newState.fullname.errors = [];
	      newState.helpBlocks.fullname = undefined;
	    }

	    this.setState(newState, resolve);
	  });
	};

	validateAffiliate = (affiliateId) => {
	  return new Promise((resolve) => {
	    this.setState(
	      {
	        ...this.state,
	        affiliateId
	      },
	      resolve
	    );
	  });
	};

	validateDateOfBirth = (value) => {
	  return new Promise((resolve) => {
	    const newState = { ...this.state };
	    newState.dateOfBirth = value;
	    const errors = validateDateOfBirth(newState.dateOfBirth);
	    if (errors.length) {
	      newState.dateOfBirth = {
	        month: '',
	        date: '',
	        year: '',
	        errors
	      };
	      newState.helpBlocks.dateOfBirth = errors;
	    } else {
	      newState.dateOfBirth.errors = [];
	      newState.helpBlocks.dateOfBirth = null;
	    }

	    this.setState(newState, resolve);
	  });
	};

	setMaxDate = () => {
	  const today = new Date();
	  // - 18 yrs
	  const date = new Date(today.setYear(today.getFullYear() - 18));
	  let day = date.getDate();
	  let month = date.getMonth() + 1;
	  const year = date.getFullYear();

	  if (day < 10) {
	    day = `0${day}`;
	  }
	  if (month < 10) {
	    month = `0${month}`;
	  }
	  return `${year}-${month}-${day}`;
	};

	updateDOB = (event) => {
	  event.stopPropagation();
	  event.preventDefault();
	  const dateValue = event.target.value.split('-');
	  const currentYear = new Date().getFullYear();
	  const age = currentYear - parseInt(dateValue[0]);
	  if (age >= 100) {
	    dateValue[0] = currentYear - 100;
	  }
	  const dateOfBirth = {
	    month: dateValue[1],
	    date: dateValue[2],
	    year: dateValue[0]
	  };
	  this.validateDateOfBirth(dateOfBirth);

	  // attempt fix for UI issue on datepicker and keyboard
	  try {
	    setTimeout(() => {
	      const redirectInput = document.getElementById('LABEL_AFFILIATE');
	      redirectInput.focus();
	      redirectInput.select();
	    }, 250);
	  } catch (error) {
	    console.log('SPA >> Registration -> DOB', error);
	  }
	};

	calculatePasswordStrength = () => {
	  if (!this.state.password.value.length) {
	    return null;
	  }

	  let score = 0;
	  let strength = '';
	  let msg = null;

	  // award every unique letter until 5 repetitions
	  const letters = {};
	  const len = this.state.password.value.length;

	  for (let a = 0; a < len; a++) {
	    letters[this.state.password.value[a]] =
				(letters[this.state.password.value[a]] || 0) + 1;
	    score += 5.0 / letters[this.state.password.value[a]];
	  }

	  // bonus points for mixing it up
	  let variationCount = 0;

	  if (/\d/.test(this.state.password.value)) {
	    variationCount += 1;
	  }

	  if (/[a-z]/.test(this.state.password.value)) {
	    variationCount += 1;
	  }

	  if (/[A-Z]/.test(this.state.password.value)) {
	    variationCount += 1;
	  }

	  if (/\W/.test(this.state.password.value)) {
	    variationCount += 1;
	  }

	  score = parseInt(score + (variationCount - 1) * 10, 10);

	  if (score < 33) {
	    strength = style.weak;
	    msg = (
	      <p>
	        {getTranslation('LABEL_REGISTER_PASS_WEAK')}
:
	        {getTranslation('LABEL_REGISTER_PASS_WEAK_DESC')}
	      </p>
	    );
	  } else if (score <= 66 || score <= 80) {
	    strength = style.mediumLow;
	    msg = (
	      <p>
	        {getTranslation('LABEL_REGISTER_PASS_MEDIUM')}
:
	        {getTranslation('LABEL_REGISTER_PASS_MEDIUM_DESC')}
	      </p>
	    );
	  } else {
	    strength = style.strong;
	    msg = (
	      <p>
	        {getTranslation('LABEL_REGISTER_PASS_STRONG')}
:
	        {getTranslation('LABEL_REGISTER_PASS_STRONG_DESC')}
	      </p>
	    );
	  }

	  return (
	    <div className={`${style.strengthIndicator} ${strength}`}>
	      <span className={style.meter} />
	      <span className={style.meter} />
	      <span className={style.meter} />
	      {msg}
	    </div>
	  );
	};

	focusField = (focusedField) => {
	  this.setState({
	    ...this.state,
	    focusedField: focusedField || null
	  });
	};

	renderHelpBlocks = (key) => {
	  if (!this.state.helpBlocks[key]) {
	    return null;
	  }

	  if (this.state.helpBlocks[key] instanceof Array) {
	    return this.state.helpBlocks[key].map((translationKey) => (
	      <span
	        key={translationKey}
	        className="help-block"
	        dangerouslySetInnerHTML={{
	          __html: getTranslation(translationKey)
	        }}
	      />
	    ));
	  }

	  return (
	    <span
	      className="help-block"
	      dangerouslySetInnerHTML={{
	        __html: getTranslation(this.state.helpBlocks[key])
	      }}
	    />
	  );
	};

	renderUsernameSuggestions = () => {
	  if (this.state.usernameSuggestions.length > 0) {
	    return (
	      <div className={style.usernameSuggestions}>
	        <div>
	          <span className={style.usernameAvailable}>
	            {getTranslation('LABEL_USERNAME_AVAILABLE')}
	          </span>
	          {this.state.usernameSuggestions.map((username) => {
	            return (
	              <span
	                className={style.username}
	                onClick={() => {
	                  this.setState({
	                    ...this.state.username,
	                    username: {
	                      ...this.state.username,
	                      value: username,
	                      errors: []
	                    },
	                    usernameSuggestions: []
	                  });
	                }}
	              >
	                {username}
	              </span>
	            );
	          })}
	        </div>
	      </div>
	    );
	  }
	};

	render = (props) => {
	  if (this.state.fetching) return <LoaderRing fullpage />;
	  return (
	    <div className={style.defaultForm}>
	      <form onSubmit={this.submit}>
	        <FormGroup>
	          <div className={style.icomoon}>
	            <img
	              src={replaceUrlPlaceholders(
	                '{ASSET_DOMAIN_CDN}icons/v1/profile.png'
	              )}
	              alt="profile.png"
	            />
	          </div>
	          <span style={{ flexDirection: 'column', width: '100%' }}>
	            <FormInput
	              title={getTranslation('LABEL_USERNAME')}
	              labelSuffix="register"
	              placeholder={getTranslation('LABEL_USERNAME')}
	              type="text"
	              value={this.state.username.value}
	              errors={this.state.username.errors}
	              maxLength="16"
	              id={this.state.username.errors ? 'has-error' : ''}
	              onInput={(e) => this.validateUsername(e.target.value)}
	              onBlur={() => {
	                !this.state.usernameSuggestions.length
	                  ? this.validateUsername(this.state.username.value)
	                  : null;
	                this.focusField();
	              }}
	              onFocus={() => this.focusField('username')}
	              hideErrorMessages={this.state.focusedField !== 'username'}
	            />
	            {this.state.focusedField === 'username'
	              ? this.renderHelpBlocks('username')
	              : null}
	            {this.renderUsernameSuggestions()}
	          </span>
	        </FormGroup>
	        <FormGroup>
	          <div className={style.icomoon}>
	            <img
	              src={replaceUrlPlaceholders(
	                '{ASSET_DOMAIN_CDN}icons/v1/password.png'
	              )}
	              alt="password.png"
	            />
	          </div>
	          <span style={{ flexDirection: 'column', width: '100%' }}>
	            <FormInput
	              title={getTranslation('LABEL_PASSWORD')}
	              type="password"
	              labelSuffix="register"
	              maxLength="20"
	              placeholder={getTranslation('LABEL_PASSWORD')}
	              id={this.state.password.errors ? 'has-error' : ''}
	              hideErrorMessages={this.state.focusedField !== 'password'}
	              onFocus={() => this.focusField('password')}
	              onInput={(e) => this.validatePassword(e.target.value)}
	              onBlur={() => this.validatePassword(this.state.password.value)}
	              value={this.state.password.value}
	              errors={this.state.password.errors}
	            />
	            {this.state.focusedField === 'password'
	              ? this.renderHelpBlocks('password')
	              : null}
	            {this.calculatePasswordStrength()}
	          </span>
	        </FormGroup>
	        {this.props.selectedLanguage === 'th' ? null : (
	          <FormGroup>
	            <div className={style.icomoon}>
	              <img
	                src={replaceUrlPlaceholders(
	                  '{ASSET_DOMAIN_CDN}icons/v1/password.png'
	                )}
	                alt="password.png"
	              />
	            </div>
	            <span style={{ flexDirection: 'column', width: '100%' }}>
	              <FormInput
	                title={getTranslation('LABEL_PASSWORD_CONFIRM')}
	                type="password"
	                placeholder={getTranslation('LABEL_PASSWORD_CONFIRM')}
	                autoCorrect="off"
	                autoCapitalize="off"
	                maxLength="20"
	                value={this.state.confirmPassword.value}
	                errors={this.state.confirmPassword.errors}
	                id={this.state.confirmPassword.errors ? 'has-error' : ''}
	                onFocus={() => this.focusField('confirmPassword')}
	                onInput={(e) => this.validateConfirmPassword(e.target.value)}
	                onBlur={() => {
	                  this.validateConfirmPassword(
	                    this.state.confirmPassword.value
	                  );
	                  this.focusField();
	                }}
	              />
	              {this.state.focusedField === 'confirmPassword'
	                ? this.renderHelpBlocks('confirmPassword')
	                : null}
	            </span>
	          </FormGroup>
	        )}
	        <FormGroup>
	          <div className={style.icomoon}>
	            <img
	              src={replaceUrlPlaceholders(
	                '{ASSET_DOMAIN_CDN}icons/v1/currency.png'
	              )}
	              alt="currency.png"
	            />
	          </div>
	          <span style={{ flexDirection: 'column', width: '100%' }}>
	            <FormDropdown
	              title={getTranslation('LABEL_CURRENCY')}
	              autoCorrect="off"
	              autoCapitalize="off"
	              data={
	                props.currency && props.currency.data
	                  ? props.currency.data.CurrencyList
	                  : []
	              }
	              errors={this.state.currencyCode.errors}
	              hideErrorMessages={this.state.focusedField !== 'currencyCode'}
	              value={this.state.currencyCode.value}
	              id={this.state.currencyCode.errors ? 'has-error' : ''}
	              onFocus={() => this.focusField('currencyCode')}
	              onBlur={() => {
	                this.validateCurrencyCode(this.state.currencyCode.value);
	                this.focusField();
	              }}
	              onInput={(e) => this.validateCurrencyCode(e.target.value)}
	            />
	            <span className="help-block">
	              {getTranslation('LABEL_REGISTER_DESC_CURRENCY_WARN')}
	            </span>
	          </span>
	        </FormGroup>
	        <FormGroup>
	          <div className={style.icomoon}>
	            <img
	              src={replaceUrlPlaceholders(
	                '{ASSET_DOMAIN_CDN}icons/v1/profile.png'
	              )}
	              alt="profile.png"
	            />
	          </div>
	          <span style={{ flexDirection: 'column', width: '100%' }}>
	            <FormInput
	              title={getTranslation('LABEL_FULLNAME')}
	              type="text"
	              placeholder={getTranslation('LABEL_FULLNAME')}
	              autoCorrect="off"
	              autoCapitalize="off"
	              value={this.state.fullname.value}
	              errors={this.state.fullname.errors}
	              hideErrorMessages={this.state.focusedField !== 'fullname'}
	              id={this.state.fullname.errors ? 'has-error' : ''}
	              onFocus={() => this.focusField('fullname')}
	              onBlur={() => {
	                this.validateFullname(this.state.fullname.value);
	                this.focusField();
	              }}
	              onInput={(e) => this.validateFullname(e.target.value)}
	            />
	            {this.state.focusedField === 'fullname'
	              ? this.renderHelpBlocks('fullname')
	              : null}
	            <span className="help-block card">
	              {getTranslation('LABEL_REGISTER_NOTE')}
	            </span>
	          </span>
	        </FormGroup>
	        <FormGroup>
	          <div className={style.icomoon}>
	            <img
	              src={replaceUrlPlaceholders(
	                '{ASSET_DOMAIN_CDN}icons/v1/email.png'
	              )}
	              alt="email.png"
	            />
	          </div>
	          <span style={{ flexDirection: 'column', width: '100%' }}>
	            <FormInput
	              title={getTranslation('LABEL_EMAIL')}
	              type="email"
	              placeholder={getTranslation('LABEL_EMAIL')}
	              autoCorrect="off"
	              autoCapitalize="off"
	              id={this.state.email.errors ? 'has-error' : ''}
	              onFocus={() => this.focusField('email')}
	              onInput={(e) => this.validateEmail(e.target.value)}
	              onBlur={() => this.validateEmail(this.state.email.value)}
	              value={this.state.email.value}
	              errors={this.state.email.errors}
	            />
	            {this.state.focusedField === 'email'
	              ? this.renderHelpBlocks('email')
	              : null}
	          </span>
	        </FormGroup>
	        <FormGroup>
	          <div className={style.icomoon}>
	            <img
	              src={replaceUrlPlaceholders(
	                '{ASSET_DOMAIN_CDN}icons/v1/cellphone.png'
	              )}
	              alt="cellphone.png"
	            />
	          </div>
	          <span style={{ width: '25%', alignSelf: 'flex-end' }}>
	            <FormDropdown
	              title={getTranslation('LABEL_MOBILE_NUMBER')}
	              data={
	                props.countryPhoneList && props.countryPhoneList.data
	                  ? props.countryPhoneList.data.PhoneList
	                  : []
	              }
	              value={this.state.countryCode.value}
	              onFocus={() => this.focusField('countryCode')}
	              onInput={(ev) => {
	                this.validateCountryCode(ev.target.value);
	                this.validatePhoneNumber(this.state.phone.value);
	              }}
	              onBlur={() => {
	                this.validateCountryCode(this.state.countryCode.value);
	                this.validatePhoneNumber(this.state.phone.value);
	                this.focusField();
	              }}
	              errors={this.state.countryCode.errors}
	              hideErrorMessages
	              name="countryCode"
	            />
	          </span>
	          <span
	            style={{
	              width: '75%',
	              alignSelf: 'flex-end',
	              marginLeft: '20px'
	            }}
	          >
	            <FormInput
	              type="number"
	              placeholder={getTranslation('LABEL_MOBILE_NUMBER')}
	              autoCorrect="off"
	              autoCapitalize="off"
	              value={this.state.phone.value}
	              errors={this.state.phone.errors}
	              customErrors
	              onFocus={() => this.focusField('phone')}
	              onInput={(ev) => {
	                if (
	                  !/[^0-9]+/gim.test(ev.target.value) ||
										!ev.target.value.length
	                ) {
	                  this.validatePhoneNumber(ev.target.value);
	                }
	              }}
	              onBlur={() => this.validatePhoneNumber(this.state.phone.value)}
	            />
	          </span>
	        </FormGroup>
	        <div
	          style={{
	            marginTop: '-25px',
	            marginLeft: '46px',
	            paddingBottom: '15px'
	          }}
	        >
	          {this.state.phone.errors && (
	            <span style={{ color: 'red' }} className="help-block">
	              {getTranslation(this.state.phone.errors)}
	            </span>
	          )}
	        </div>
	        <div
	          style={{
	            marginTop: '-10px',
	            marginLeft: '46px',
	            paddingBottom: '15px'
	          }}
	        >
	          {this.props.selectedLanguage === 'th' &&
						this.state.countryCode.value &&
						this.state.countryCode.value !== '66' ? (
	              <span className="help-block">
	                {getTranslation('LABEL_REGISTER_DESC_CONTACT')}
	              </span>
	            ) : this.state.countryCode.value &&
						  this.state.countryCode.value === '66' &&
						  (this.props.selectedLanguage !== 'th' ||
								this.props.selectedLanguage === 'th') ? (
	                <span className="help-block">
	                  {getTranslation('ERR_OMIT_ZERO_NOTIF')}
	                </span>
	              ) : (
	                this.renderHelpBlocks('phone')
	              )}
	        </div>
	        <div className={style.container}>
	          <FormGroup>
	            <div className={style.icomoon}>
	              <img
	                src={replaceUrlPlaceholders(
	                  '{ASSET_DOMAIN_CDN}icons/v1/calendar.png'
	                )}
	                alt="calendar.png"
	              />
	            </div>
	            <div
	              className={
	                this.state.dateOfBirth.date ? style.formGroup : style.noValue
	              }
	            >
	              <FormInput
	                title={getTranslation('LABEL_DOB')}
	                type="date"
	                placeholder={getTranslation('LABEL_DOB')}
	                class={style.dobDropdown}
	                max={this.setMaxDate()}
	                onChange={(e) => {
	                  this.updateDOB(e);
	                }}
	                autoCorrect="off"
	                autoCapitalize="off"
	                value={`${this.state.dateOfBirth.year}-${this.state.dateOfBirth.month}-${this.state.dateOfBirth.date}`}
	                id={this.state.dateOfBirth.errors ? 'has-error' : ''}
	                formattedDateValue={
	                  this.state.dateOfBirth.date != '' &&
										this.state.dateOfBirth.month != '' &&
										this.state.dateOfBirth.year != ''
	                    ? `${this.state.dateOfBirth.date}/${this.state.dateOfBirth.month}/${this.state.dateOfBirth.year}`
	                    : getTranslation('LABEL_DOB')
	                }
	                errors={this.state.dateOfBirth.errors}
	                disableFutureDates
	                disableKeypress
	                style={{
	                  background: 'none'
	                }}
	              />
	            </div>
	            <div className={style.calendar}>
	              <span
	                className="icon-filter"
	                style={{
	                  color: 'white',
	                  fontSize: '1.2rem',
	                  marginLeft: '30px',
	                  paddingRight: '15px'
	                }}
	              />
	            </div>
	          </FormGroup>
	        </div>
	        <FormGroup>
	          <div className={style.icomoon}>
	            <img
	              src={replaceUrlPlaceholders(
	                '{ASSET_DOMAIN_CDN}icons/v1/affiliate.png'
	              )}
	              alt="affiliate.png"
	            />
	          </div>
	          <span style={{ flexDirection: 'column', width: '100%' }}>
	            <FormInput
	              disabled={this.state.hasAffiliateId}
	              title={getTranslation('LABEL_AFFILIATE')}
	              id="LABEL_AFFILIATE"
	              type="text"
	              placeholder={getTranslation('LABEL_AFFILIATE')}
	              value={this.state.hasAffiliateId || this.state.affiliateId}
	              onBlur={() => this.focusField()}
	              onFocus={() => this.focusField('affiliate')}
	              onInput={(ev) => {
	                if (!/[^\d]/.test(ev.target.value)) {
	                  this.validateAffiliate(ev.target.value);
	                }
	              }}
	              maxLength={9}
	            />
	            {this.state.focusedField === 'affiliate' &&
							!(this.state.hasAffiliateId || this.state.affiliateId) ? (
	                <span className="help-block">
	                  {getTranslation('LABEL_REGISTER_DESC_AFFILIATE_ID')}
	                </span>
	              ) : null}
	          </span>
	        </FormGroup>
	        <FormGroup style={{ flexDirection: 'column' }}>
	          <Text>{getTranslation('LABEL_REGISTER_DISCLAIMER')}</Text>
	        </FormGroup>
	        <Button>{getTranslation('LABEL_JOIN')}</Button>
	      </form>
	    </div>
	  );
	};
}

export default connect(['countryPhoneList', 'currency', 'selectedLanguage'])(
  FormSample
);
