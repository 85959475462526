import { store, updateStore } from '_unistore';
import { xhr, urlCountryPhoneList, replaceUrlPlaceholders } from '_helpers';

// eslint-disable-next-line import/prefer-default-export
export function fetchCountryPhoneList () {
  // curreny state
  const { countryPhoneList } = store.getState();
  // initial state
  updateStore({
    countryPhoneList: {
      ...countryPhoneList,
      fetching: true,
      result: false
    }
  });

  return xhr(replaceUrlPlaceholders(urlCountryPhoneList))
    .then((res) => {
      updateStore({
        countryPhoneList: {
          data: res.ResponseData,
          fetching: false,
          result: true
        }
      });
      return true;
    })
    .catch(() => {
      updateStore({
        countryPhoneList: {
          ...countryPhoneList,
          fetching: false,
          result: false
        }
      });
      return false;
    });
}
