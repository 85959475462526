export const urlUserData = `${process.env.API_DOMAIN}_secure/ajax/user/account?_={_}`;
export const urlGrid = `${process.env.API_DOMAIN}/grids?language.code={langCode}`;
export const urlLiveChatLink = `${process.env.API_DOMAIN}_secure/ajax/livechat?_={_}`;
export const urlAnalytics = 'https://skynet.whiteproj.com';
export const urlTranslation =
	'/{PUBLIC_PATH}assets/data/translations/{langAlias}.json';
export const urlPopupModule = '/{PUBLIC_PATH}assets/data/popupmodule.json';
export const urlCurrency = '/{PUBLIC_PATH}assets/data/currency.json';
export const urlCountryPhoneList =
	'/{PUBLIC_PATH}assets/data/countryPhoneList.json';
export const urlMenu = `${process.env.API_DOMAIN}/menus?language.code={langCode}`;
export const urlPosts = `${process.env.API_DOMAIN}/posts?language.code={langCode}`;
export const urlFeaturedPosts = `${process.env.API_DOMAIN}/posts?isFeatured=true&language.code={langCode}`;
export const urlTags = `${process.env.API_DOMAIN}/tags?language.code={langCode}`;
export const urlLanguage = `${process.env.API_DOMAIN}/languages`;

// forms from sso
export const urlRegister = `${process.env.API_DOMAIN}_secure/ajax/v1/{langCode}/{consumer}/{device}/member/forgotPassword`;
