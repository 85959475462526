/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-unused-vars */
import { h, render, Component } from 'preact';
// eslint-disable-next-line import/extensions
import style from './style';

class Text extends Component {
	render = (props) => {
	  return (
	    <div className={`${style.textcomp}`}>
	      <div className={`${style.container}`}>
	        <p className={props.isCentered ? `${style.center}` : ''}>
	          <span className={props.isHeadingText ? ` ${style.heading}` : ''}>
	            {props.text}
	          </span>
	        </p>
	      </div>
	    </div>
	  );
	};
}

export default Text;
