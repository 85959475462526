import { store, updateStore } from '_unistore';
import { xhr, urlCurrency } from '_helpers';

// eslint-disable-next-line import/prefer-default-export
export function fetchCurrency () {
  // curreny state
  const { currency } = store.getState();
  // initial state
  updateStore({
    currency: {
      ...currency,
      fetching: true,
      result: false
    }
  });

  return xhr(urlCurrency)
    .then((res) => {
      updateStore({
        currency: {
          data: res.ResponseData,
          fetching: false,
          result: true
        }
      });
      return true;
    })
    .catch(() => {
      updateStore({
        currency: {
          ...currency,
          fetching: false,
          result: false
        }
      });
      return false;
    });
}
